export default {
  OP : 'Print',
  OMP : 'Mounted Print',
  SP : 'Small Print',
  SMP : 'Small Mounted Print',
  LP : 'Large Print',
  LMP : 'Large Mounted Print',
  MP : 'Medium Print',
  MMP : 'Medium Mounted Print',
  XLP : 'Extra Large Print'
}
