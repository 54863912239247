<template>
  <v-dialog v-model="value" @click:outside="close" scrollable max-width="90vw">
    <v-container fluid :style="{ padding: 0 }">
      <v-card>
        <v-toolbar
          flat
          dark
          color="primary"
        >
          <v-toolbar-title>
            {{ currentProduct?.title }} by {{ currentProduct?.artist }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col md="6" sm="12">
              <v-carousel light v-model="carouselItem" :hide-delimiter-background=true delimiter-icon="mdi-stop-circle">
                <v-carousel-item
                  v-for="(item,i) in productImages"
                  :key="i"
                  :src="`${imageUrl}/1500px/${item}`"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  contain
                ></v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col md="6" sm="12" class="d-flex flex-column">
              <p class="text-h6 mb-2">
                {{currentProduct.category}}{{currentProduct.editionSize && ` ( signed edition of ${currentProduct.editionSize} )` }}
              </p>
              <v-simple-table border="1" >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="px-2">SKU</th>
                        <th></th>
                        <th class="px-0">Price</th>
                      <th class="pl-2" v-if="isCustomer">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="currentVariant === index && 'active'" class="v-clickable" v-for="(variant, index) in currentProduct.variants" :key="variant.sku" @click="setVariant(index)">
                      <td class="px-2">{{ variant.sku }}</td>
                      <td>
                        {{ variant.variantName }}
                        {{ variant.dimensions &&`(${variant.dimensions})`}}
                        </td>
                      <td class="px-0">{{ `£${variant.unitPrice.toFixed(2)}` }}</td>
                      <td v-if="isCustomer" class="pl-2">
                        <add-order-item :item="{
                          sku: variant.sku,
                          unitPrice: variant.unitPrice,
                          minQty: currentProduct.minQty,
                          multQty: currentProduct.multQty
                        }" :cart="cart" />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="mt-4">
              <h4>Product Description</h4>
              {{
                currentProduct.mountedPrintSize && `Mounted print size approximately ${currentProduct.mountedPrintSize}`  ||
                currentProduct.greetingCardSize && `Size ${currentProduct.greetingCardSize} `||
                currentProduct.magnetSize && `Size ${currentProduct.magnetSize} `
              }}
              </div>
              <div class="mt-2" v-html="currentProduct.longDesc">
              </div>
              <v-spacer></v-spacer>
              <vue-barcode class="v-clickable" @click.native="barcodeDownload()" ref="barcode" :value="currentProduct?.variants[currentVariant].barCode" format="EAN13" height="30" />
              <v-card-actions>
                <v-btn color="blue darken-1" :disabled="currentItem === 0" text
                  @click.stop="prev(currentItem)">Prev</v-btn>
                <v-btn color="blue darken-1" :disabled="currentItem + 1 === products.length" text
                  @click.stop="next(currentItem)">Next</v-btn>
                <!-- <v-btn color="blue darken-1" text @click.stop="downloadGreetingCard(products[currentItem].artworkImage)">Calendar</v-btn> -->
                <!-- <v-btn color="blue darken-1" text @click.stop="downloadCalendar(products[currentItem].variants[0].images)">Calendar</v-btn> -->
                <!-- <v-btn color="blue darken-1" text @click.stop="barcodeDownload(currentProduct?.variants[currentVariant].barCode)">GreetingCard</v-btn> -->
                <v-btn color="blue darken-1" text @click.stop="close">Close</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <div id="img_download_btn"></div>
        </v-card-text>
        <v-card-text>
        <template v-if="hasRelated">
          <hr />
          <v-card-title>If you like this image by {{currentProduct?.artist}} you might be interested in these</v-card-title>
          <template v-if="hasRelatedProducts">
            <v-card-title class="py-0">Products</v-card-title>
            <v-row class="mt-0">
              <v-col :key="$index" v-for="(item, $index) in relatedProducts"  xl= "2" lg="3" sm="12" cols="12" class="pt-0 d-flex flex-column">
                <v-card
                  :id="`item${$index}`"
                  :key="$index"
                  class="mx-2 my-2 d-flex flex-column flex-grow-1 fill-height"
                >
                  <v-card-text class="d-flex flex-column pb-0">
                    <h4 class="mb-0">{{item.variant}}</h4>
                  </v-card-text>
                  <v-card-text class="d-flex flex-column">
                    <v-tooltip content-class="custom-tooltip" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img v-bind="attrs" contain :aspect-ratio="1/1"  :src="`${imageUrl}/300px/${item.productImage}`" />
                      </template>
                      <v-card class="mx-auto my-auto" max-width="374">
                        <v-card-text >
                        <p v-html="item.longDesc"></p>
                        </v-card-text>
                        </v-card>
                    </v-tooltip>
                    <div class="mt-4">
                      {{item.sku }} {{ item.title }} by {{ item.artist }}
                    </div>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-text class="pt-0">
                    <div>
                      <strong>Price:</strong> -  £{{item.unitPrice.toFixed(2)}}
                    </div>
                  </v-card-text>
                  <template v-if="isCustomer" class="d-flex">
                    <add-order-item :item="{sku:item.sku,unitPrice:item.unitPrice,minQty:item.minQty,multQty:item.multQty}" :cart="cart" />
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-if="hasRelatedGiclees">
            <v-card-title class="pb-0">Fine Art Prints</v-card-title>
            <v-row class="mt-0">
              <v-col :key="$index" v-for="(item, $index) in relatedGiclees"  xl= "2" lg="3" sm="12" cols="12" class="pt-0 d-flex flex-column">
                <v-card
                  :id="`item${$index}`"
                  :key="$index"
                  class="mx-2 my-2 d-flex flex-column flex-grow-1 fill-height"
                >
                  <v-card-text class="d-flex flex-column pb-0">
                    <h4 class="mb-0">{{item.category}}</h4>
                    <p class="mb-0">{{item.variant}}</p>
                  </v-card-text>
                  <v-card-text class="d-flex flex-column">
                    <v-tooltip content-class="custom-tooltip" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img v-bind="attrs" contain :aspect-ratio="1/1"  :src="`${imageUrl}/300px/${item.productImage}`" />
                      </template>
                      <v-card class="mx-auto my-auto" max-width="374">
                        <v-card-text >
                        <p v-html="item.longDesc"></p>
                        </v-card-text>
                        </v-card>
                    </v-tooltip>
                    <div class="mt-4">
                      {{item.sku }} {{ item.title }} by {{ item.artist }}
                    </div>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-text class="pt-0">
                    <div>
                      <strong>Price:</strong> -  £{{item.unitPrice.toFixed(2)}}
                    </div>
                  </v-card-text>
                  <template v-if="isCustomer" class="d-flex">
                    <add-order-item :item="{sku:item.sku,unitPrice:item.unitPrice,minQty:item.minQty,multQty:item.multQty}" :cart="cart" />
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>
    </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProductApi from '@/api/admin/product'
import ArtworkApi from '@/api/artwork'
import AddOrderItem from '@/components/AddOrdersMenu.vue'
import ImageThumbnail from '@/components/ImageThumbnail.vue'
import VueBarcode from 'vue-barcode'
import variantText from '@/constants/productVariants'
// import productDescription from '@/constants/productDescriptions'
// import JsBarcode from 'jsbarcode'
import { svgToImgDownload } from '@/utilities/imageUtilities'
// import S3Api from '@/api/s3'

export default {
  components: {
    AddOrderItem,
    ImageThumbnail,
    VueBarcode
  },
  directives: {
  },
  props: {
    value: Boolean,
    item: Number
  },
  data() {
    return {
      tab: null,
      imageUrl: process.env.VUE_APP_IMG_URL,
      currentItem: this.item,
      carouselItem: 0,
      relatedProducts: [],
      relatedGiclees: [],
      hasRelated: false,
      hasRelatedProducts: false,
      hasRelatedGiclees: false,
      currentVariant: 0,
      thumbCols: 1,
      imgCols: 6,
      dataCols: 5,
      mainImage: 0,
      barcodeFormat: 'EAN-13',
      variantText: variantText,
      signedUrls: []
    }
  },
  computed: mapState({
    ...mapGetters([
      'isAdmin',
      'isCustomer',
      'isLoggedIn'
    ]),
    productImages() {
      return [ ...this.currentProduct.variants[this.currentVariant].productImages, this.currentProduct.artworkImage]
    },
    filters: (state) => state.filters.all,
    products: (state) => state.products.products,
    totalProducts: (state) => state.products.totalProducts,
    page: (state) => state.products.page,
    hasMoreProducts: (state) => state.products.hasNextPage,
    breakpoint: function () { return this.$vuetify.breakpoint.name },
    cart: function (state) { return state.cart },
    currentProduct: function (state) {
      const item = state.products.products[this.currentItem]
      const sortedVariants = item.variants
      sortedVariants.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)
      item.variants = sortedVariants
      item.productTypeCode = item.sku.split('-')[0]
      return item },
  }),
  methods: {
    showProductImage() {
      return !['Open Edition Giclee', 'Limited Edition Giclee'].includes(this.currentProduct?.category)
    },
    // downloadCalendar(images) {
    //   const image = images.length > 1 ? images[1] : images[0]
    //   ProductApi.getCalendarPDF(image).then((results) => {
    //     console.log(results)
    //   })
    // },
    downloadGreetingCard(image) {
      ProductApi.getGreetingCardPDF(image).then((results) => {
        console.log(results)
      })
    },
    isInCart(item) {
      return this.cart.items.findIndex((cartItem) => cartItem.sku === item.sku) > -1
    },
    async next() {
      if (this.currentItem === this.products.length - 2) {
        this.$emit('loadMore')
      } else {
        if (this.currentItem + 1 < this.totalProducts) {
          this.currentItem += 1
        }
      }
      this.carouselItem = 0
      this.currentVariant = 0
      // this.signedUrls = await Promise.all(this.productImages.map((img) => S3Api.getS3PresignedGet(`1500px/${img}`)))
      const resp = await ArtworkApi.getArtworkProducts(this.currentProduct.artworkCode)
      this.relatedProducts = resp.data.filter( product => {
        const productTypeCode = product.sku.split('-')[0]
        return product.status === 'ACTIVE' && productTypeCode !== this.currentProduct.productTypeCode && !['GICL','GICO'].includes(productTypeCode)
      })
      this.relatedGiclees = resp.data.filter( product => {
        const productTypeCode = product.sku.split('-')[0]
        return product.status === 'ACTIVE' && productTypeCode !== this.currentProduct.productTypeCode && ['GICL','GICO'].includes(productTypeCode)
      })
      this.hasRelated = this.relatedGiclees.length || this.relatedProducts.length
      this.hasRelatedProducts = this.relatedProducts.length
      this.hasRelatedGiclees = this.relatedGiclees.length
    },
    prev() {
      this.carouselItem = 0
      if (this.currentItem > 0) this.currentItem -= 1
    },
    save() {
      this.$emit('input', false)
    },
    close() {
      this.$emit('close', this.currentItem)
    },
    setVariant(index) {
      this.currentVariant = index
    },
    setMainImage(index) {
      this.mainImage = index
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          this.prev()
          break
        case 39:
          this.next()
          break
      }
    },
    barcodeDownload() {
      const sku = this.currentProduct.variants[this.currentVariant].sku
      const svg = document.querySelector(".vue-barcode-element");
      svgToImgDownload( svg, `${sku}-BARCODE.jpg` , 500, 200 )
    }
  },
  async created () {
    // this.signedUrls = await Promise.all(this.productImages.map((img) => S3Api.getS3PresignedGet(`1500px/${img}`)))
    const resp = await ArtworkApi.getArtworkProducts(this.currentProduct.artworkCode)
    this.relatedProducts = resp.data.filter( product => {
      const productTypeCode = product.sku.split('-')[0]
      return product.status === 'ACTIVE' && productTypeCode !== this.currentProduct.productTypeCode && !['GICL','GICO'].includes(productTypeCode)
    })
    this.relatedGiclees = resp.data.filter( product => {
      const productTypeCode = product.sku.split('-')[0]
      return product.status === 'ACTIVE' && productTypeCode !== this.currentProduct.productTypeCode && ['GICL','GICO'].includes(productTypeCode)
    })
    this.hasRelated = this.relatedGiclees.length || this.relatedProducts.length
    this.hasRelatedProducts = this.relatedProducts.length
    this.hasRelatedGiclees = this.relatedGiclees.length
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>
<style lang="scss" scoped>
  // tbody {
  //   tr:active {
  //     background-color: rgba(26, 75, 148, 0.2);
  //   }
  // }
  .custom-tooltip {
    opacity: 1!important;
  }
  .active { background-color: rgba(26, 75, 148, 0.2) !important}
</style>

