<template>
  <div :class="selected && 'active'">
    <v-tooltip content-class='custom-tooltip' right color="transparent" :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-img
          @click="setImage"
          @error="missingImage"
          @mouseover="over"
          class="mx-2 my-2 v-clickable"
          :height="maxSize + 'px'"
          :width="maxSize + 'px'"
          :src="`${imageUrl}/${imageSize}px/${image}`"
          v-bind="attrs"
          v-on="on"
        ></v-img>
      </template>
      <v-card>
        <v-card-text>
        <v-img style="transparency: 100%"
          max-height="300px"
          max-width="300px"
          :src="`${imageUrl}/300px/${image}`"
        ></v-img>
        </v-card-text>
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  props: {
    image: String,
    imageSize: { Type: Number, default: 100 },
    index: Number,
    selected: Boolean,
    maxSize: { Type: Number, default: 50 },
    tooltip: { Type: Boolean, default: false }
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL
    }
  },
  methods: {
    over () {
    },
    missingImage () {
      this.image = 'awaiting_image.jpg'
    },
    setImage () {
      this.$emit('setImage', this.index)
    }
  }
}
</script>
<style scoped>
  .custom-tooltip {opacity: 1!important;}
  div { border: 1px solid grey }
  .active { background-color: #eee;}
</style>
